import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import style from './App.module.css'
import Meet from './Meet'
import getDailyRoomName from './getDailyRoomName'

const configOverwrite = {
  // do not suggest other mics if main mic isn't available
  enableNoAudioDetection: false,
  // mute THIS USER ONLY on startup
  startWithAudioMuted: true,
  // video resolution
  resolution: 480,
  // stop streaming HD vidoe when it's not in view
  enabledLayerSuspension: true,
  // mute video for THIS USER ONLY on startup
  startWithVideoMuted: true
}
const interfaceConfigOverwrite = {
  CONNECTION_INDICATOR_DISABLED: true,
  DEFAULT_BACKGROUND: '#f9f8f5',
  DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
  DISABLE_RINGING: true,
  DISABLE_VIDEO_BACKGROUND: true,
  HIDE_INVITE_MORE_HEADER: true,
  MOBILE_APP_PROMO: false,
  VERTICAL_FILMSTRIP: false,
  VIDEO_QUALITY_LABEL_DISABLED: true,
  // 'microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen',
  // 'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
  // 'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
  // 'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
  // 'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone', 'security'
  TOOLBAR_BUTTONS: [
    'camera', 'chat', 'filmstrip', 'mute-everyone', 'security', 'shortcuts',
    'sharedvideo', 'tileview'
  ],
}

function App() {
  const meet = useRef(null)
  const configure = api => {
    api.on('readyToClose', () => {
      meet.current.className = style.ended
    })
    api.on('audioMuteStatusChanged', ({muted}) => {
      if (!muted) {
        api.executeCommand('toggleAudio')
      }
    })
  }
  const dailyRoomName = getDailyRoomName(new Date())
  return (
    <div className={style.app}>
      <Meet
        subject="Workfrom Cafe"
        ref={meet}
        roomName={dailyRoomName}
        onload={configure}
        configOverwrite={configOverwrite}
        interfaceConfigOverwrite={interfaceConfigOverwrite}
      />
    </div>
  );
}

App.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}

App.defaultProps = {
  height: '100%',
  width: '100%'
}

export default App;
