import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import style from './Meet.module.css'

/* global JitsiMeetExternalAPI */
function Meet({
  width, height, roomName, subject, domain,
  configOverwrite, interfaceConfigOverwrite,
  onload
}) {
  const meet = useRef(null)

  useEffect(() => {
    const api = new JitsiMeetExternalAPI(domain, {
      parentNode: meet.current,
      roomName,
      width,
      height,
      configOverwrite,
      interfaceConfigOverwrite,
      onload: () => onload(api)
    })
    if (subject) {
      api.executeCommand('subject', subject)
    }
    return () => api.dispose()
  })

  return <div ref={meet} className={style.meet} />
}

Meet.propTypes = {
  // required
  roomName: PropTypes.string,
  // optional
  subject: PropTypes.string,
  domain: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  configOverwrite: PropTypes.object,
  interfaceConfigOverwrite: PropTypes.object,
  onload: PropTypes.func
}

Meet.defaultProps = {
  subject: null,
  domain: 'workfrom.coffee',
  width: '100%',
  height: '100%',
  configOverwrite: {},
  interfaceConfigOverwrite: {},
  onload: () => {}
}


export default Meet;
